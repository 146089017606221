/* 
* {
    background: rgb(0 0 0 / .1);
}
*/

.invisible {
  display: none !important;
}

.app {
  background-color: #80663c;
  margin-bottom: 100px;
}

.app-container {
  margin: 0;
  padding: 0;
}

.app-header {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#14222f),
    to(#34485d)
  );
  background-image: linear-gradient(#14222f, #34485d);
  -webkit-box-shadow: 0 2px 20px rgb(0 0 0 / 50%);
  box-shadow: 0 2px 20px rgb(0 0 0 / 50%);
  height: 8rem;
  padding-left: 10%;
  padding-top: 40px;
}

.app-header .logo {
  background: url(https://www.schechterwealth.com/themes/custom/schechterwealth/img/logo.svg)
    no-repeat 0;
  background-size: contain;
  height: 4.625rem;
  width: 10rem;
  margin: 0 0 0 0px;
  padding: 0;
  display: block;
  text-indent: -9999px;
  z-index: 101;
}

.app-body {
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  background-color: #ffffff;
  display: inline-block;

  font-family: Arial, Helvetica, sans-serif;
  color: #30475c;
}

.app-body > p {
  font-family: "Times New Roman", Times, serif;
  font-weight: normal;
  color: #80663c;
  font-size: 32px;
}

.app-body fieldset {
  border: none;
  margin: 0;
  padding: 0;
  margin-bottom: 50px;
}

fieldset input {
  border-radius: 7px;
  height: 2.5rem;
  border-color: #30475c;
  border: 1px solid;
  font-size: 16px;
  padding-inline: 1em;
  letter-spacing: 0.4pt;
}

fieldset input::placeholder {
  color: #30475c;
  opacity: 1; /* Firefox */
  font-size: 16px;
  padding-left: 5%;
}

fieldset input:focus::placeholder {
  visibility: hidden;
}

#labName input {
  margin-right: 15px;
}

#divEmailPhone label {
  display: inline-block;
  margin-right: 15px;
}

/* CHECKBOXES */
.checkbox-label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
  cursor: pointer;
}

.checkbox-label > * {
  height: 100%;
}

/* Hide the default checkbox */
input[type="checkbox"] {
  visibility: hidden;
}

input[type="radio"] {
  visibility: hidden;
}

/* creating a custom checkbox based on demand */
.checkbox-span {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 20px;
  width: 20px;
  background-color: white;
  border-color: #30475c;
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
}

/* specify the background color to be shown when hovering over checkbox */
.checkbox-label:hover input ~ .checkbox-span {
  background-color: white;
}

/* specify the background color to be shown when checkbox is active */
.checkbox-label input:active ~ .checkbox-span {
  background-color: white;
}
/* specify the background color to be shown when checkbox is checked */
.checkbox-label input:checked ~ .checkbox-span {
  background-color: white;
}
/* checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.checkbox-span:after {
  content: "X";
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
}
/* display checkmark when checked */
.checkbox-label input:checked ~ .checkbox-span:after {
  display: block;
}
/* styling the checkmark using webkit */
/* creating a square to be the sign of checkmark */
.checkbox-label .checkbox-span:after {
  font-size: 20px;
  text-align: center;
}

button[type="submit"] {
  height: 50px;
  width: 150px;
  border-width: 0px;
  color: white;
  border-radius: 10px;
  background-color: #30475c;
  font-size: 22px;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

button[type="submit"]:hover {
  background-color: #50687c;
}

#dietaryRestrictionsList > * {
  margin-bottom: 10px;
}

input {
  font-family: Arial, Helvetica, sans-serif;
}

#divEmailPhone label {
  display: inline-block;
  margin-right: 15px;
}

#flightInformationBlock > input {
  margin-right: 15px;
}

#btnSubmit {
  cursor: pointer;
  width: calc(250px + 20%);
}

a {
  color: #80663c;
  text-decoration: none;
  font-weight: bold;
}
